<template>
  <div>
    <!--      左侧菜单栏-->
    <a-col :span="4">
      <a-menu
        style="min-height: 300px"
        mode="vertical"
        :default-selected-keys="activeMenuTab"
        v-model="activeMenuTab"
        v-if="isShowMenu('sim_cards/service_cycle_manage')"
      >
        <a-menu-item
          v-for="item in showHasPermissionMenus"
          :key="item.routeName"
        >
          <router-link :to="{ name: item.routeName }">
            <a-icon :type="item.iconType" />
            {{ item.menuName }}
          </router-link>
        </a-menu-item>
      </a-menu>
    </a-col>
    <!--      内容-->
    <a-col :span="20">
      <div class="simiot-vertical-tab-content">
        <router-view />
      </div>
    </a-col>
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'

export default {
  name: 'AgentServiceCycleManageTab',
  data() {
    return {
      activeMenuTab: [],
      allMenus: [
        {
          routeName: 'service_cycle_unexpired',
          menuName: '服务周期即将到期',
          iconType: 'file-sync',
          permission: 'sim_cards/service_cycle_manage'
        },
        {
          routeName: 'service_cycle_expired',
          menuName: '服务周期已到期',
          iconType: 'exception',
          permission: 'sim_cards/service_cycle_manage'
        },
        {
          routeName: 'current_month_expect_cancel',
          menuName: '本月预计销卡列表',
          iconType: 'file-sync',
          permission: 'sim_cards/service_cycle_manage'
        },
        {
          routeName: 'service_cycle_renewed',
          menuName: '续期记录',
          iconType: 'file-sync',
          permission: 'sim_cards/service_cycle_manage'
        },
        {
          routeName: 'service_cycle_canceled',
          menuName: '销卡记录',
          iconType: 'close-circle',
          permission: 'sim_cards/service_cycle_manage'
        }
      ],
      showHasPermissionMenus: []
    }
  },
  computed: {
    agentId() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {
    this.activeMenuTab = [this.$route.name]
    this.fetchShowHasPermissionMenus()
  },
  methods: {
    fetchShowHasPermissionMenus() {
      let isIncludeActiveMenuTab = false

      this.allMenus.forEach((value, index) => {
        if (this.isShowMenu(value.permission)) {
          this.showHasPermissionMenus.push(value)
          if (this.activeMenuTab[0] === value.routeName) {
            isIncludeActiveMenuTab = true
          }
        }
      })

      // 如果有权限的目录中不包含activeKey，那么activeKey 等于目录中第一个路径名
      if (!isIncludeActiveMenuTab) {
        this.activeKey = this.showHasPermissionMenus[0].routeName
      }
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    }
  }
}
</script>
